import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="Mevry | AI for online shops" />
    <h1>PAGE NOT FOUND</h1>
  </Layout>
);

export default NotFoundPage;
